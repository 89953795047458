import Button, { ButtonSize, ButtonType } from "components/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { useContextUser } from "contexts/user";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  closeModal?: Function;
};

export const SocialAccounts = ({}: Props) => {
  const { t } = useTranslation(["common"]);
  const { userData } = useContextUser();

  useEffect(() => {
    localStorage.setItem("origin", "on");
  }, []);

  return (
    <>
      <div className="md:px-2 sm:px-0 md:px-4 py-3">
        <a
          href={`${process.env.REACT_APP_OAUTH_URL}/?origin=webapp&action=addSocial&userId=${userData?.userId}`}
        >
          <Button
            type={ButtonType.secondary}
            size={ButtonSize.medium}
            fullWidth
          >
            <div className="text-gray-light60">
              <FontAwesomeIcon icon={faUser} size="2x" />
            </div>
            <span className="pl-6">{t(`addSocials`, { ns: "common" })}</span>
          </Button>
        </a>
      </div>
    </>
  );
};
