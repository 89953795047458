import { BlockchainType, Protocol } from "models/Enums";
import { ContractsCW20, Token, TokensCW20 } from "models/Interfaces";
import { LCDClient, Validator } from "@terra-money/terra.js";
import {
  getNftUrl,
  validateRadixWallet,
  validateTerraWallet,
} from "components/notifications/utils";
import { terra, terraClassic } from "shared";

import { NFTCollectionsMap } from "components/notifications/notificationTypes/NFTMonitor";
import { Pagination } from "@terra-money/terra.js/dist/client/lcd/APIRequester";
import keccak256 from "keccak256";

export const selectCurrentBlockchain = (protocol: string) => {
  return protocol === BlockchainType.TerraClassic ? terraClassic : terra;
};

export async function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// check wallet is valid
export const isValidTerraWallet = (wallet: string): boolean | undefined => {
  if (wallet === "") return;
  return validateTerraWallet.test(wallet) ? true : false;
};

// check wallet is valid
export const isValidRadixWallet = (wallet: string): boolean | undefined => {
  if (wallet === "") return;
  return validateRadixWallet.test(wallet) ? true : false;
};

/* FUNCTIONS - PROFILE CHANGES */
export const getValidators = async (
  blockchain: BlockchainType
): Promise<Validator[]> => {
  let finished = false;

  const validators: Validator[] = [];
  let offset: string | undefined = undefined;
  while (!finished) {
    const valds: [Validator[], Pagination] = await selectCurrentBlockchain(
      blockchain
    )?.staking.validators({
      "pagination.key": offset,
    });
    valds[0].forEach((v) => validators.push(v));
    offset = valds[1].next_key ?? undefined;
    if (offset === undefined) {
      finished = true;
    }
    await sleep(1000);
  }

  return validators;
};

export const getDelegationsByWalletAddress = async (
  walletAddress: string,
  blockchain: BlockchainType
): Promise<string[]> => {
  const protocol = selectCurrentBlockchain(blockchain);
  const delegations = await protocol.staking.delegations(walletAddress);
  return delegations[0].map((delegation) => delegation.validator_address);
};

/* FUNCTIONS - PRICE ALERT
 */
// get promise tokens and contracts and return data (contracts assets and tokens) needs
export const formatDataTokens = async (blockchain: string) => {
  const ContractsAndTokens = await getDataTokens(blockchain as BlockchainType);
  const arrAux: any = [];
  return (
    ContractsAndTokens &&
    ContractsAndTokens?.map(async (el: any) => {
      const DATA = await el;
      const contractsArr = DATA?.map((object: ContractsCW20 & TokensCW20) => {
        const result = Object.entries(object).filter(([key, value]: any) => {
          if (key === "asset_infos") {
            return value?.map((assets: {}) => {
              const data = Object.values(assets).map((cryptos: any) => {
                const assets_info = Object.entries(cryptos).reduce(
                  (ac: any[], crypto): any => {
                    if (!arrAux.length) arrAux.push(crypto[1]);
                    else ac.push(...[arrAux.pop(), crypto[1]]);
                    return ac;
                  },
                  []
                );
                return assets_info.length !== 0 && assets_info;
              })?.[0];

              return data !== false && data;
            })?.[1];
          }
          return key === "liquidity_token" || (key === "dex" && value);
        });
        return result.length && result[1] !== undefined
          ? { [object?.contract_addr]: result[0].concat(result[1], result[2]) }
          : object;
      });
      return contractsArr;
    })
  );
};

export const removeTokensUndefined = async (dataTokens: any) => {
  return await dataTokens?.map((dt: {}) => {
    return Object.entries(dt)?.filter(([contract, pairs]: any) => {
      if (
        contract !== undefined &&
        pairs[0] !== undefined &&
        pairs[1] !== undefined
      ) {
        return { [contract]: [pairs[0], pairs[1]] };
      } else {
        return false;
      }
    })[0];
  });
};
// create array with contractsAddr and respectives tokens
export const formatPresentation = async (
  blockchain: string,
  project?: string
) => {
  const arrData = await formatDataTokens(blockchain);
  const contracts = await arrData[0];
  const tokens = await arrData[1];
  const dataTokens = contracts.map((contract: any) => {
    const dataContracts = Object.values(contract).map((c: any) => {
      if (c[5] && project?.toLowerCase().indexOf(c[5]?.toLowerCase()) !== -1) {
        return Object.values(c[1])?.map((crypto1: any) => {
          const eachToken: any = Object.values(crypto1)?.[0];
          return Object.values(eachToken).map((value: any) => {
            const dataFilter = tokens.filter((t: any) => {
              return Object.values(t)?.includes(value);
            })?.[0];
            return dataFilter && { ...dataFilter };
          })?.[0];
        });
      } else return [];
    });

    const contractData: Array<String> = Array.from(Object.values(contract));
    return (
      dataContracts[0].length > 0 && {
        [String(Object.keys(contract))]: {
          ...dataContracts[0]?.concat(
            {
              liquidity_pool: contractData[0][3],
            },
            { dex: contractData[0][5] }
          ),
        },
      }
    );
  });

  return dataTokens;
};

export const getInfoCW20ByBlockchain = async (
  protocol: string,
  justContracts: boolean = true,
  justTokens: boolean = true
): Promise<any> => {
  const endpointContracts =
    protocol === BlockchainType.TerraClassic
      ? `${process?.env.REACT_APP_CONTRACT_LUNC_URL}`
      : `${process?.env.REACT_APP_CONTRACT_LUNA_URL}`;
  const endpointTokens =
    protocol === BlockchainType.TerraClassic
      ? `${process?.env.REACT_APP_TOKENS_LUNC_URL}`
      : `${process?.env.REACT_APP_TOKENS_LUNA_URL}`;
  const result = [];

  try {
    const contracts =
      (justContracts &&
        (await fetch(`${endpointContracts}`, { method: "GET" }))) ||
      null;

    const tokens =
      (justTokens && (await fetch(`${endpointTokens}`, { method: "GET" }))) ||
      null;

    if (justContracts && contracts !== null) {
      !contracts.ok
        ? console.error(
            `Hermes-Protocol says: Error fetching  ${contracts.status} from ${contracts.url}`
          )
        : result.push(contracts.json());
    }
    if (justTokens && tokens !== null)
      !tokens.ok
        ? console.error(
            `Hermes-Protocol says: Error fetching  ${tokens.status} from ${tokens.url}`
          )
        : result.push(tokens.json());

    return result.length &&
      ((contracts && contracts.ok) || (tokens && tokens!.ok))
      ? result
      : false;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getDataTokens = async (protocol: BlockchainType) => {
  // Get all data from git [ContractsCW20 and TokensCW20]
  const ALL_DATA = await getInfoCW20ByBlockchain(protocol!);
  // Passing on key and value in order to access promise
  return (
    ALL_DATA &&
    ALL_DATA!.map((contractsAndTokens: Promise<ContractsCW20 | TokensCW20>) => {
      return contractsAndTokens.then((data: ContractsCW20 | TokensCW20) => {
        const result = Object.entries(data).map(([k, v]: any) => {
          return Object.fromEntries(
            Object.entries(v).filter(
              ([name, value]) =>
                ((name === "icon" ||
                  name === "name" ||
                  name === "denom" ||
                  name === "token" ||
                  name === "symbol" ||
                  name === "contract_addr" ||
                  name === "asset_infos") &&
                  value) ||
                (name === "liquidity_token" && v) ||
                (name === "dex" && v)
            )
          );
        });
        return [...result];
      });
    })
  );
};

export const returnContractAvailables = (
  tokens: TokensCW20[] & ContractsCW20[]
) => {
  return tokens.filter((data: TokensCW20 | boolean) => data !== false);
};

/* FUNCTIONS - NFT */
// return
export const getInfoNFTByContract = async (
  contract: string,
  protocol: string | BlockchainType,
  query: string = `{"all_tokens":{}}`
) => {
  // terra Classic API - OLD
  // Luna new - mainnet
  // Testei e esta tudo ok, se continuar sem problema -apagar esse bloco comentado-
  /* const endPointAssets =
    protocol.toLocaleLowerCase() === "classic"
      ? `https://cdn.luart.io/mainnet/${contract}/nft-compact-metadata.json`
      : `https://assets.hermesprotocol.io/blockchains/terra/nfts/metadata/${contract}.json`;
 */
  let endPointAssets: string = "";
  let endPointBlockchain: string = "";

  switch (
    protocol // less code static
  ) {
    case Protocol.Classic.toLowerCase():
    case BlockchainType.TerraClassic: {
      endPointAssets = `https://cdn.luart.io/mainnet/${contract}/nft-compact-metadata.json`; // Não temos assets ainda para o terra classic
      endPointBlockchain = `${process.env.REACT_APP_TERRA_CLASSIC_CONTRACT_URL2}${contract}`;
      break;
    }
    case Protocol.MainNet.toLowerCase():
    case BlockchainType.Terrav2: {
      const encodedQuery = Buffer.from(query).toString("base64");
      endPointAssets = `https://assets.hermesprotocol.io/blockchains/terra/nfts/metadata/${contract}.json`;
      //      endPointBlockchain = `${process.env.REACT_APP_TERRA_CONTRACT_URL}${contract}/smart/${encodedQuery}`;
      endPointBlockchain = `${process.env.REACT_APP_TERRA_CONTRACT_URL}${contract}/smart/${encodedQuery}`;

      break;
    }
    case BlockchainType.Near: {
      // future
      break;
    }
  }

  try {
    let responseReturn;
    const response = await fetch(endPointAssets, { method: "GET" });

    if (response && !response.ok) {
      responseReturn = await fetch(endPointBlockchain, { method: "GET" });

      if (responseReturn && responseReturn.ok) return responseReturn.json();

      if (!responseReturn.ok && !response.ok) {
        console.error(
          `Hermes-Protocol says: Error fetching ${
            response.status || responseReturn.status
          } from ${endPointAssets || endPointBlockchain}`
        );
      }
    }
    return response.ok && response.json();
  } catch (e) {
    console.log(e);
    return false;
  }
};

// Return all nfts of collections formatted
export const getNftByCollection = async (
  contract: string,
  blockchain: string
) => {
  const tokens = await getInfoNFTByContract(contract, blockchain);

  if (!tokens) {
    return [];
  }

  /* Quando existir transformTypeTokensByChain[0]?.["tokens"], chamar novamente a função await getInfoNFTByContract com a query de base 64 ex: {"nft_info":{"token_id":"001a2d06899338aaa3483cc80068e6c4826d30268857b3e308049026cc6a8ed7"}}.
  O retorno atual é: (Não me serve de nada, sem informação de jeito)
// https://phoenix-lcd.terra.dev/cosmwasm/wasm/v1/contract/terra16a6qkmxpqzeyez8gh3w7qhrk7x3xe3arlv9nwfg944y8vzg9smrqntark3/smart/eyJuZnRfaW5mbyI6eyJ0b2tlbl9pZCI6IjAwMWEyZDA2ODk5MzM4YWFhMzQ4M2NjODAwNjhlNmM0ODI2ZDMwMjY4ODU3YjNlMzA4MDQ5MDI2Y2M2YThlZDcifX0=
{
  "data": {
    "token_uri": null,
    "extension": {
      "name": "henry",
      "image": null,
      "image_data": null,
      "email": null,
      "external_url": null,
      "public_name": "henry",
      "public_bio": null,
      "twitter": null,
      "discord": null,
      "telegram": null,
      "github": null,
      "contract_address": null,
      "parent_token_id": null,
      "pgp_public_key": null
    }
  }
}
*/
  // temp code
  const transformTypeTokensByChain = Array.isArray(tokens)
    ? tokens
    : Object.values(tokens); // waiting for DuDucks (Classic list NFTs)

  const result =
    transformTypeTokensByChain[0]?.["tokens"] !== undefined
      ? transformTypeTokensByChain[0]?.["tokens"]
      : transformTypeTokensByChain?.map((value) => {
          const obj: Token =
            blockchain === "classic" ||
            blockchain === BlockchainType.TerraClassic
              ? {
                  image: value.imageURL,
                  name: value.name,
                  tokenId: value.tokenId,
                }
              : {
                  image: getNftUrl(value.image),
                  name: value.name,
                  tokenId: value.tokenId,
                }; //temp

          return Object.assign({}, obj);
        });

  return result;
};

export const getDataCollectionBlockchain = async (
  contract: string,
  protocol: string
): Promise<any> => {
  const blockchain = selectCurrentBlockchain(protocol);
  return await blockchain.wasm.contractInfo(contract);
};

// depois de ler as coleções da url , buscar em cada contract se para essa wallet tem nfts
export const getUsersNft = async (
  blockchainObj: LCDClient,
  nftAddress: string,
  collections: NFTCollectionsMap,
  isSaveInLocalStorage: boolean // changing fn to use in trading option
): Promise<any> => {
  const data: { [key: string]: {} }[] = [];
  collections &&
    Object.keys(collections).map(async (contract: string) => {
      try {
        const infoToken: Array<[{}]> = await blockchainObj.wasm.contractQuery(
          `${contract}`,
          {
            tokens: { owner: `${nftAddress}` },
          }
        );

        if (Object.values(infoToken)?.[0].length > 0) {
          const dataTosave = {
            [contract]: { ...Object.values(infoToken)?.[0] },
          };
          if (isSaveInLocalStorage) {
            const userNftListing = localStorage.getItem("user_nft_listing");
            if (userNftListing !== null) {
              Object.assign(dataTosave, JSON.parse(userNftListing));
              localStorage.removeItem("user_nft_listing");
            }
            localStorage.setItem(
              "user_nft_listing",
              JSON.stringify(dataTosave)
            );
          }
          data.push(dataTosave);
        }
      } catch (e) {
        console.error(e);
      }
    });

  return data;
};

/**
 * Get Collections from selected blockchain
 * merging another function - getInfoCW20ByBlockchain
 * @param blockchain
 * @returns Object
 *  */
export const getCollections = async (blockchain: BlockchainType) => {
  let buildURL: string = "";
  switch (blockchain) {
    case BlockchainType.TerraClassic:
      buildURL = `${process.env.REACT_APP_CONTRACTS_NFT_LUNC_URL}`;
      break;
    case BlockchainType.Terrav2:
      buildURL = `${process.env.REACT_APP_CONTRACTS_NFT_LUNA_URL}`;
      break;
    case BlockchainType.Radix:
      buildURL = `${process.env.REACT_APP_CONTRACTS_RADIX}`;
  }

  try {
    const result = await fetch(`${buildURL}`, { method: "GET" });
    if (!result.ok) {
      console.error(
        `Hermes-Protocol says: Error fetching ${result.status} from ${buildURL}`
      );
      return null;
    }
    return result.json();
  } catch (e) {
    return null;
  }
};

export const getNftListingLocalStorage = () => {
  return localStorage.getItem("user_nft_listing");
};

export const loadingNFTUserByCollection = async (
  blockchain: BlockchainType,
  walletAddress: string,
  collectionList: NFTCollectionsMap,
  isSaveInLocalStorage: boolean = true
) => {
  const blockchainObj = selectCurrentBlockchain(blockchain);

  const result = await getUsersNft(
    blockchainObj,
    walletAddress,
    collectionList,
    isSaveInLocalStorage
  );

  return isSaveInLocalStorage
    ? getNftListingLocalStorage()
    : result !== undefined && result;
};
/**
 * Get Domain from wallet
 * O nuno disse que a url quando é terra classic esta errado mas também disse que como não usamos o terra classic não tem problema.
 * @param walletAddress, chainId
 * @returns Object
 *  */
export const getDomain = async (walletAddress: string, chainId: string) => {
  const buildURL =
    chainId === BlockchainType.Terrav2
      ? `${process.env.REACT_APP_TERRA_CONTRACT_URL}`
      : `${process.env.REACT_APP_TERRA_CLASSIC_CONTRACT_URL}`;

  const query = `{
        "reverse_record": {
          "address": "${walletAddress}"
        }
      }`;

  const encodedQuery = Buffer.from(query).toString("base64");

  try {
    const connection = await fetch(
      `${buildURL}${process.env.REACT_APP_TERRA_SMART_CONTRACT}/smart/${encodedQuery}`,
      {
        method: "GET",
      }
    );

    const result = await connection.json();
    return result.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getDomainExpiration = async (domain: string, chainId: string) => {
  const buildURL =
    chainId === BlockchainType.Terrav2
      ? `${process.env.REACT_APP_TERRA_CONTRACT_URL}`
      : `${process.env.REACT_APP_TERRA_CLASSIC_CONTRACT_URL}`;

  const hashedDomain = keccak256(domain).toString("hex");

  const queryExpiration = `{
    "expires_at": {
        "token_id": "${hashedDomain}"
    }
  }`;

  const encodedQueryExpiration =
    Buffer.from(queryExpiration).toString("base64");

  try {
    const connection = await fetch(
      `${buildURL}${process.env.REACT_APP_TERRA_SMART_CONTRACT}/smart/${encodedQueryExpiration}`,
      {
        method: "GET",
      }
    );

    const result = await connection.json();

    return result.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

/* P2P trade */
export const getCW20TokensByWallet = async (
  blockchain: string,
  walletAddress: string
): Promise<any> => {
  // we'll need to create a pagination, for now listing 10000 tokens per wallet
  const endpointTokens =
    blockchain !== BlockchainType.TerraClassic
      ? `${process?.env.REACT_APP_TERRA_CW20TOKENS_URL}/${walletAddress}?pagination.limit=10000`
      : `${process?.env.REACT_APP_TERRA_CLASSIC_CW20TOKENS_URL}/${walletAddress}?pagination.limit=10000`;

  try {
    const tokens = await fetch(`${endpointTokens}`, { method: "GET" });
    if (!tokens.ok) {
      !tokens.ok &&
        console.error(
          `Hermes-Protocol says: Error fetching  ${tokens.status} from ${tokens.url}`
        );
      return false;
    }
    return tokens.json();
  } catch (error) {
    return false;
  }
};
