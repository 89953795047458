import Button, { ButtonSize, ButtonType } from "components/Button";
import Loader, { LoaderType } from "components/Loader";
import PopUp, { PopupSize } from "../components/Popup";
import React, { useEffect, useState } from "react";
import SideMenu, { ModeType } from "../components/SideMenu";
import { WalletStatus, useWallet } from "@terra-money/wallet-provider";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "../components/Logo";
import { MfaEnabling } from "../components/auth/MfaEnabling";
import ModalAuth from "../components/auth/ModalAuth";
import { NavLink } from "react-router-dom";
import RightSectionLogin from "../components/RightSectionLogin";
import { Warning } from "../components/modals";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { instanceOfUserData } from "utils/instanceOf";
import { useContextUser } from "contexts/user";
import { useTranslation } from "react-i18next";

export enum SignUpStep {
  connectWallet = "connectWallet",
  verifyWallet = "verifyWallet",
  signUpCompleted = "signUpCompleted",
}

export enum ModalState {
  verify = "verify",
  success = "success",
  failure = "failure",
  hasUser = "hasUser",
  noUser = "noUser",
  noSocial = "noSocial",
  mfa = "mfa",
  mfaFailure = "mfaFailure",
  mfaValidate = "mfaValidate",
  guestUser = "guestUser",
}

export enum Network {
  columbus5 = "columbus-5",
  phoenix1 = "phoenix-1",
  pisco1 = "pisco-1",
}

/* Sign in component receives as props from the App the global variable that holds user data, updates it and sends back */
export const SignInPage: React.FC = () => {
  const { t } = useTranslation(["common", "enumerations"]);
  const { status } = useWallet();
  const { setUser } = useContextUser();

  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isTermsChecked, setIsTermsChecked] = useState(
    status === WalletStatus.WALLET_CONNECTED
  );
  const [width, setWidth] = useState(window.innerWidth);
  const [currentStep, setCurrentStep] = useState(
    status === WalletStatus.WALLET_CONNECTED
      ? SignUpStep.verifyWallet
      : SignUpStep.connectWallet
  );
  const [modalContent, setModalContent] = useState<null | ModalState>(null);
  const [txError, setTxError] = useState<string | null>(null);
  const [tempUserData, setTempUserData] = useState<any>(null);
  const [qrCode, setQrCode] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [showMfaEnabling, setShowMfaEnabling] = useState(false);

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  const handleModal = (content: any) => {
    setIsOpenModal && setIsOpenModal(true);
    setModalContent(content);
  };

  const handleStep = (step: any) => {
    setCurrentStep && setCurrentStep(step);
  };

  const handleSetUser = () => {
    setTimeout(() => {
      setIsOpenModal(false);
      setModalContent(null);
      setUser(tempUserData);
    }, 3000);
  };

  const handleLoggedInUser = async () => {
    setIsOpenModal(false);
    setIsLoading(true);
    try {
      const connection = await fetch(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/fauna/auth/mfa-verify`,
        {
          method: "POST",
          body: JSON.stringify({
            userId: tempUserData.userId,
            token,
          }),
          credentials: "include",
        }
      );
      const dataFromApi = await connection.json();
      if (connection.status === 400) {
        setTxError(dataFromApi.data.error);
        handleModal(ModalState.mfaFailure);
      } else if (
        typeof dataFromApi.data === "object" &&
        instanceOfUserData(dataFromApi.data)
      ) {
        setTempUserData(dataFromApi.data);
        handleModal(ModalState.success);
        handleStep(SignUpStep.signUpCompleted);
      } else {
        handleModal(ModalState.failure);
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const handleResetModal = () => {
    setTimeout(() => {
      setIsOpenModal(false);
      setModalContent(null);
    }, 2000);
  };

  const handleWarningText = () => {
    if (txError) {
      return txError;
    } else {
      return t("modal_content.failure.modal_title", {
        ns: "enumerations",
      });
    }
  };

  const handleTryMfaAgain = () => {
    setTimeout(() => {
      handleModal(ModalState.mfa);
    }, 2000);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });

  return (
    <>
      {isLoading && <Loader type={LoaderType.fullScreen} />}
      <div className="flex flex-grow">
        <SideMenu authPage={true} mode={ModeType.wide}>
          <Logo />
          <h2 className="self-center text-md md:text-lg">
            {t("signup", {
              ns: "common",
            })}
          </h2>
          <p className="self-center static mt-10 text-sm">
            {t("modal_content.has_account", { ns: "enumerations" })}
            <NavLink to="/" className={"text-muddywaters-500"}>
              {t("login", {
                ns: "common",
              })}
            </NavLink>
          </p>
        </SideMenu>

        <RightSectionLogin />

        {isOpenModal && (
          <>
            {currentStep === SignUpStep.connectWallet && (
              <PopUp
                size={PopupSize.large}
                closeModal={() => setIsOpenModal(false)}
              >
                {width < 1024 && (
                  <>
                    {isTermsChecked && (
                      <div className="flex flex-col">
                        <div id="back-button">
                          <Button
                            type={ButtonType.cancel}
                            size={ButtonSize.small}
                            onClick={() => setIsTermsChecked(false)}
                          >
                            <div>
                              <FontAwesomeIcon icon={faChevronLeft} />
                            </div>
                          </Button>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </PopUp>
            )}
            {modalContent === ModalState.verify && (
              <PopUp size={PopupSize.large}>
                <ModalAuth
                  modalTitle={t("modal_content.verify_wallet.modal_title", {
                    ns: "enumerations",
                  })}
                  additionalTitle={t(
                    "modal_content.verify_wallet.additional_title",
                    {
                      ns: "enumerations",
                    }
                  )}
                  logo={true}
                >
                  {handleResetModal()}
                </ModalAuth>
              </PopUp>
            )}
            {modalContent === ModalState.success && (
              <PopUp size={PopupSize.large}>
                <ModalAuth
                  modalTitle={t("modal_content.verified_wallet", {
                    ns: "enumerations",
                  })}
                  logo={true}
                >
                  {handleSetUser()}
                </ModalAuth>
              </PopUp>
            )}
            {modalContent === ModalState.mfa && qrCode && (
              <PopUp size={PopupSize.largeXL}>
                <ModalAuth
                  modalTitle={t("modal_content.mfa.modal_title", {
                    ns: "enumerations",
                  })}
                  logo={true}
                >
                  <h3 className="font-normal w-11/12 md:w-8/12 text-center mb-5">
                    {t("modal_content.mfa.additional_title", {
                      ns: "enumerations",
                    })}
                    .{" "}
                    <a
                      href="https://docs.hermesprotocol.io/docs/webapp/2FA/"
                      target="_blank"
                      rel="noreferrer"
                      className="underline"
                    >
                      {t("modal_content.mfa.learn_more", {
                        ns: "enumerations",
                      })}
                    </a>
                  </h3>
                  <label className="switch mb-5">
                    <input
                      type="checkbox"
                      onChange={() => setShowMfaEnabling(!showMfaEnabling)}
                    />
                    <span className={"slider round checked:bg-black"}></span>
                  </label>
                  {showMfaEnabling && (
                    <MfaEnabling
                      qrCode={qrCode}
                      tempUserData={tempUserData}
                      setToken={setToken}
                    />
                  )}
                  <div className="text-right pt-5">
                    <Button
                      onClick={() => handleLoggedInUser()}
                      disabled={
                        showMfaEnabling && (!token || token?.length !== 6)
                      }
                      className="text-white bg-gold active:bg-yellow-700 text-base px-6 py-2 rounded shadow lg:hover:shadow-lg outline-none focus:outline-none "
                    >
                      {t("signup", {
                        ns: "common",
                      })}
                    </Button>
                  </div>
                </ModalAuth>
              </PopUp>
            )}
            {modalContent === ModalState.mfaFailure && (
              <PopUp size={PopupSize.large}>
                <Warning
                  title={"Warning"}
                  text={handleWarningText()}
                  iconWarning={true}
                />
                {handleTryMfaAgain()}
              </PopUp>
            )}
          </>
        )}
      </div>
    </>
  );
};
