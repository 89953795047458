import Button, { ButtonSize, ButtonType } from "components/Button";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { ModalState } from "views/SignIn";
import { Warning } from "./Warning";
import { setTimeout } from "timers";
import { useContextUser } from "contexts/user";
import { useTranslation } from "react-i18next";

export enum WalletSettingsState {
  ongoing = "ongoing",
  connectWallet = "connectWallet",
  walletVerified = "walletVerified",
  hasWallet = "hasWallet",
  verifyFailure = "verifyFailure",
}

export const WalletsFlow: React.FC<{
  closeModalMain?: Dispatch<SetStateAction<any>>;
}> = ({ closeModalMain }) => {
  const { t } = useTranslation(["common"]);
  const { userData, setUser } = useContextUser();

  const [width, setWidth] = useState(window.innerWidth);
  const [modalContent, setModalContent] = useState<
    ModalState | WalletSettingsState
  >(WalletSettingsState.ongoing);

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  const closeModal = () => {
    setTimeout(() => {
      closeModalMain && closeModalMain(false);
    }, 2000);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });

  return (
    <div className="py-2">
      {modalContent === WalletSettingsState.ongoing && (
        <>
          <a
            href={`${process.env.REACT_APP_OAUTH_URL}/?origin=webapp&action=addWallet&userId=${userData?.userId}`}
            className="mt-4"
          >
            <Button
              type={ButtonType.secondary}
              size={width < 640 ? ButtonSize.mediumShorter : ButtonSize.medium}
              fullWidth
              className="py-1.5"
            >
              <div className="text-gray-light60">
                <img
                  src="images/logo_icon.png"
                  alt="Hermes Protocol"
                  className="w-9"
                />
              </div>
              <span className="pl-6">{t(`hermesLogin`, { ns: "common" })}</span>
            </Button>
          </a>
        </>
      )}
      {modalContent === WalletSettingsState.hasWallet && (
        <>
          <Warning
            title={"Warning"}
            text={t("modal_content.has_wallet.modal_title", {
              ns: "enumerations",
            })}
            iconWarning={true}
          />

          {closeModal()}
        </>
      )}
      {modalContent === WalletSettingsState.walletVerified && (
        <>
          <Warning
            title={"Congratulations"}
            text={t("modal_content.new_wallet_added", {
              ns: "enumerations",
            })}
            colorIcon={"bg-gold"}
            iconSuccess={true}
          />
          {closeModal()}
        </>
      )}
    </div>
  );
};
