import PopUp, { PopupSize } from "components/Popup";
import SideMenu, { ModeType } from "../components/SideMenu";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import LoginSectionButtons from "../components/auth/LoginSectionButtons";
import ModalAuth from "../components/auth/ModalAuth";
import { ModalState } from "./SignIn";
import RightSectionLogin from "../components/RightSectionLogin";
import successIcon from "../assets/images/success_icon.svg";
import { useContextUser } from "contexts/user";
import { useTranslation } from "react-i18next";

export enum LoginStep {
  connectWallet = "connectWallet",
  verifyWallet = "verifyWallet",
}

export const LoginPage: React.FC = () => {
  const { t } = useTranslation(["common", "enumerations"]);
  const { setUser } = useContextUser();
  const navigate = useNavigate();
  const { state }: any = useLocation();
  let dataFromHandleSocials = state;

  const [width, setWidth] = useState(window.innerWidth);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState<null | ModalState>(null);
  const [tempUserData, setTempUserData] = useState(null);

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  const handleSetUser = () => {
    setTimeout(() => {
      setIsOpenModal(false);
      setModalContent(null);
      setUser(tempUserData);
    }, 3000);
  };

  const handleCloseModal = (hasButton: boolean) => {
    if (hasButton) {
      setIsOpenModal(false);
      dataFromHandleSocials = null;
      setModalContent(null);
    } else {
      setTimeout(() => {
        setIsOpenModal(false);
        dataFromHandleSocials = null;
        setModalContent(null);
      }, 3000);
    }
  };

  useEffect(() => {
    if (dataFromHandleSocials !== null) {
      if (dataFromHandleSocials.userId) {
        setTempUserData(dataFromHandleSocials.userId);
      }
      setIsOpenModal(true);
      setModalContent(dataFromHandleSocials.modalContent);
      dataFromHandleSocials = null;
    }
  }, [dataFromHandleSocials]);

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  return (
    <>
      <div className="flex flex-grow">
        <SideMenu authPage={true} mode={ModeType.wide}>
          <div className="flex flex-col">
            <h2 className="self-center text-md md:text-lg">
              {t("login", {
                ns: "common",
              })}
            </h2>
            <LoginSectionButtons />
          </div>
        </SideMenu>
        <RightSectionLogin />
        {isOpenModal && (
          <>
            {modalContent === ModalState.success && (
              <PopUp size={PopupSize.large}>
                <ModalAuth
                  modalTitle={t(
                    "modal_content.connect_wallet_success.modal_title",
                    { ns: "enumerations" }
                  )}
                  modalImage={successIcon}
                >
                  {handleSetUser()}
                </ModalAuth>
              </PopUp>
            )}
            {modalContent === ModalState.noSocial && (
              <PopUp size={PopupSize.large}>
                <ModalAuth
                  modalTitle={t("ups", { ns: "common" })}
                  additionalTitle={t(
                    "enumerations:modal_content.user_does_not_have_social"
                  )}
                  logo={true}
                >
                  {handleCloseModal(false)}
                </ModalAuth>
              </PopUp>
            )}
            {modalContent === ModalState.guestUser && (
              <PopUp size={PopupSize.largeXL}>
                <ModalAuth
                  modalTitle={t("modal_content.user_is_guest.modal_title", {
                    ns: "enumerations",
                  })}
                  name={dataFromHandleSocials.username}
                  additionalTitle={t(
                    "modal_content.user_is_guest.additional_title",
                    {
                      ns: "enumerations",
                    }
                  )}
                  logo={true}
                  primaryButton={t(
                    "modal_content.user_is_guest.primary_button",
                    { ns: "enumerations" }
                  )}
                  secondaryButton={t(
                    "modal_content.user_is_guest.secondary_button",
                    { ns: "enumerations" }
                  )}
                  primaryButtonOnClick={() => handleCloseModal(true)}
                  secondaryButtonOnClick={() => navigate("/")}
                />
              </PopUp>
            )}
          </>
        )}
      </div>
    </>
  );
};
